import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import Layout from '../../components/shared/layout';
import SEO from '../../components/SEO/SEO';
import styled from '@emotion/styled';
import { wrapper, breakpoints } from '../../utils/style';
import Parallax from '../../components/shared/Backgrounds/Parallax';
import Breadcrumbs from '../../components/shared/Breadcrumbs';
import ContentFromWp from '../../components/shared/ContentFromWP';
import SimilarNews from '../../components/shared/SimilarNews';
import BottomBanner from '../../components/Content/Home/BottomBanner';

const WrapperDesktop = styled("div")`
  ${wrapper}
  .top-wrap {
    display: flex;
    flex-flow: row-wrap;
    align-items: center;
    margin-bottom: 30px;
  }
  .featured-wrapper { 
    margin-right: 40px;
    flex: 0 0 294px;
  }
  .gatsby-image-wrapper {
    border-radius: 60px 30px 30px 30px;
  }
  @media (max-width: ${breakpoints.xs}) {
    .top-wrap {
      display: block;
      margin-bottom: 0;
    }
    .featured-wrapper {
      margin: 0 0 25px;
    }
    .gatsby-image-wrapper {
      border-radius: 30px 20px 20px 20px;
    } 
  }
`;


const PostTemplate = ({ data, pageContext }) => {
  const post = data.wordpressPost;
  const metaTitle = (post.yoast_meta && post.yoast_meta.yoast_wpseo_title)
    ? post.yoast_meta.yoast_wpseo_title : post.title + +"- " + process.env.OWNER;
  const metaDesc = (post.yoast_meta && post.yoast_meta.yoast_wpseo_metadesc)
    ? post.yoast_meta.yoast_wpseo_metadesc : "";
  const breadcrumbs = pageContext.breadcrumbs;
  const page_title = pageContext.page_title;

  return (
    <Layout>
      <SEO
        title={metaTitle}
        description={metaDesc}
        pathname={post.path}
        key="seo"
        breadcrumbs={breadcrumbs}
      />
      <Parallax />
      <WrapperDesktop>
        <Breadcrumbs breadcrumbs={breadcrumbs} page_title={page_title} />
        <div className="top-wrap">
          {post.featured_media?.localFile?.childImageSharp?.fluid &&
          <div className="featured-wrapper" key="featured-wrapper">
            <Img fluid={post.featured_media.localFile.childImageSharp.fluid}
                 alt={post.featured_media.alt_text} />
          </div>
          }
          <h1 className="h1 a-center gradient" dangerouslySetInnerHTML={{ __html: post.title }} />
        </div>
        <ContentFromWp>
          <div key="content" dangerouslySetInnerHTML={{ __html: post.content }} />
        </ContentFromWp>
        <SimilarNews posts={data.allWordpressPost}/>
        <BottomBanner banner={data.home.edges[0].node.acf.bottom_banner}/>
      </WrapperDesktop>
    </Layout>
  )
};

PostTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array
};

export default PostTemplate;

export const pageQuery = graphql`
    query($id: String!, $related: [Int],) {
        wordpressPost(id: { eq: $id }) {
          wordpress_id
          title
          content
          path
          date(formatString: "MMMM DD, YYYY")
          yoast_meta {
              yoast_wpseo_title
              yoast_wpseo_metadesc
          }
          featured_media {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 294) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        allWordpressPost(filter: {wordpress_id: {in: $related}}, limit:3) {
          edges {
            node {
              slug
              title
              featured_media {
                alt_text
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 330) {
                     ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
        home: allWordpressPage(filter: { slug: { eq: "home" } }) {
          edges {
            node {
              acf {
                bottom_banner {
                  acf {
                    slide_url
                    slide_title
                    slide_description
                  }    
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1190, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                }
              }
            }
          }  
        }
    }
`;

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { color, breakpoints } from '../../utils/style';
import Img from 'gatsby-image';

const Wrapper = styled('div')`
  margin: 35px 0 50px;
  .h3 {
    margin: 2rem 0;
    min-height: 50px;
  }
  ul {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    list-style: none;
    margin-bottom: 30px;
  }
  li {
    flex-basis: 32%;
    position: relative;
  }

  .item-link {
    position: relative;
    display: block;
    padding: 40px 0 0 40px;
    font-size: 21px;
    line-height: 1.52;

    .image-container {
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 50px 20px 20px 20px;
      width: calc(100% - 40px);
    }

    .gatsby-image-wrapper {
      border-radius: 50px 20px 20px 20px;
    }

    .post-title-block {
      padding: 319px 32px 33px;
      border-radius: 20px;
      background: ${color.white};
      box-shadow: 0 24px 48px 0 rgba(0, 24, 52, 0.08);
      color: ${color.black};
    }

    &:hover,
    &:active {
      text-decoration: none;
      .post-title-block {
        color: ${color.red};
        box-shadow: 0 24px 48px 0 rgba(0, 24, 52, 0.12);
      }
    }
  }
  
  
  @media (max-width: ${breakpoints.lg}) {
    .item-link {
      .post-title-block {
        padding-top: 98%;
      }
    }
  }
  



  @media (max-width: ${breakpoints.sm}) {
    margin-bottom: 20px;
    ul {
      margin-bottom: 5px;
    }
    li:first-of-type {
      flex-basis: 100%;
      margin-bottom: 20px;
      .item-link {
        padding: 20px 0 0 20px;
        .image-container {
          width: calc(100% - 20px);
        }
      }
    }
    li {
      flex-basis: 47%;
      margin-bottom: 20px;
      .item-link {
        padding: 20px 0 0 5px;
        font-size: 1rem;
        .post-title-block {
          padding: 100% 20px 20px;
        }
        .image-container {
          width: 100%;
        }
      }
    }
    
    
  }
`;

const SimilarNews = ({posts}) => (
  <Wrapper>
    <h3 className="h1 a-center gradient">
      <span>Similar News</span>
    </h3>
    <ul>
      {posts.edges.map(item => (
        <li key={item.node.label + item.node.slug}>
          <Link className="item-link" to={'/' + item.node.slug + '/'}>
            <div className="image-container">
              {item.node.featured_media?.localFile?.childImageSharp && (
                <Img
                  fluid={item.node.featured_media.localFile.childImageSharp.fluid} fadeIn={false} loading='eager'
                  alt={item.node.featured_media.alt_text ? item.node.featured_media.alt_text : item.node.title}
                />
              )}
            </div>
            <div className="post-title-block">
              <span className="post-title">{item.node.title}</span>
            </div>
          </Link>
        </li>
      ))}
    </ul>
  </Wrapper>
);

export default SimilarNews;

SimilarNews.propTypes = {
  posts: PropTypes.object
};
